import React, { useState, useEffect } from "react"
import { UseAuthContext } from '../context/AuthContext';
import { variables } from "../Variables";
import moment from 'moment';
import { DataGrid } from '@mui/x-data-grid';
import Pagination from '@mui/material/Pagination';
import axios from 'axios';
import { useParams } from "react-router-dom";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export const Log = () => {

    const [triggers, setTriggers] = useState([]);
    const [estateId, setEstateId] = useState("");
    const [triggerId, setTriggerId] = useState("");
    const [contactId, setContactId] = useState("");
    const [parserId, setParserId] = useState("");
    const [status, setStatus] = useState("");
    const [error, setError] = useState("");
    const [appointCreateStartDate, setAppointCreateStartDate] = useState("");
    const [appointCreateEndDate, setAppointCreateEndDate] = useState("");
    const [appointmentStartDate, setAppointmentStartDate] = useState("");
    const [appointmentEndDate, setAppointmentEndDate] = useState("");
    const [paginationLength, setPaginationLength] = useState(10);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [finalPagingCount, setFinalPagingCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [triggerList, setTriggerList] = useState([]);
    const [statusDropDown, setStatusDropDown] = useState([]);
    const [errorDropDown, setErrorDropDown] = useState([]);


    const params = useParams();
    const { officeTriggerId } = params;

    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = UseAuthContext();

    const filterTriggerEmailsLog = async () => {
        setLoading(true);

        let _triggerId = 0;
        if (officeTriggerId != null && officeTriggerId != undefined && (triggerId == "" || triggerId == null)) {
            _triggerId = officeTriggerId;
        }
        else {
            _triggerId = triggerId != "" && triggerId != null ? +triggerId.value : 0
        }

        const objBody = {
            "pageSize": +pageSize,
            "pageCount": +pageCount,
            "triggerId": _triggerId,
            "eventStartMinDate": appointmentStartDate != "" ? appointmentStartDate : null,
            "eventStartMaxDate": appointmentEndDate != "" ? appointmentEndDate : null,
            "eventCreatedMinDate": appointCreateStartDate != "" ? appointCreateStartDate : null,
            "eventCreatedMaxDate": appointCreateEndDate != "" ? appointCreateEndDate : null,
            "contactId": contactId != "" ? +contactId : 0,
            "estateId": estateId != "" ? +estateId : 0,
            "status": status,
            "error": error,
            "parserId": parserId != "" ? +parserId : 0,
        }


        if (authUser == null) {
            return
        }

        let url = variables.API_URL + `OfficeTrigger/GetTriggerEmailsLogWithPaging`;

        const config = {
            headers: {
                'Authorization': `Bearer ${authUser.tokenValue}`,
                'Content-Type': 'application/json'
            },
        };

        try {
            const response = await axios.post(url, JSON.stringify(objBody), config);
            const jsonData = response.data;
            setPaginationLength(jsonData.count);
            setTriggers(jsonData.emailTransLogList);
            console.log(jsonData.emailTransLogList);
            setLoading(false);
        } catch (error) {
            console.log(error.message);
        }
    }

    const clearFilterRecord = () => {
        setLoading(true);
        setTriggerId("");
        setEstateId("");
        setContactId("");
        setParserId("");
        setStatus("");
        setError("");
        setAppointCreateEndDate("");
        setAppointCreateStartDate("");
        setAppointmentEndDate("");
        setAppointmentStartDate("");
        filterTriggerEmailsLog();
        setLoading(false);
    }

    const paginationNo = (event, value) => {
        const countValue = value;
        setPageCount(countValue);
    };

    const handlePageSize = (event) => {
        const value = +event.target.value;
        setPageSize(value);

        const newPageCount = Math.ceil(paginationLength / value);
        if (pageCount > newPageCount) {
            setPageCount(newPageCount);
        }
    }

    const pagCount = () => {
        if (paginationLength.length != 0) {
            const pageNo = paginationLength / pageSize;
            if (pageNo < 1) {
                setFinalPagingCount(1);
            } else {
                const intValue = Math.ceil(pageNo);
                setFinalPagingCount(intValue)
            }
        }
    }

    const getTriggerList = async () => {
        if (authUser == null) {
            return;
        }

        try {
            const response = await fetch(`${variables.API_URL}OfficeTrigger/GetAllTriggers`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authUser.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const jsonData = await response.json();

            if (jsonData != null) {
                const filterTriggerId = jsonData.map(item => ({
                    label: `${item.officeTriggerid}`,
                    value: `${item.officeTriggerid}`
                }));
                setTriggerList(filterTriggerId);
            } else {
                console.warn("Received null data");
            }
        } catch (error) {
            console.error("Failed to fetch trigger List:", error.message);
        }
    };

    const getTriggerStatusesHandle = async () => {
        if (authUser == null) {
            return;
        }

        try {
            const response = await fetch(`${variables.API_URL}OfficeTrigger/GetTriggerStatuses`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authUser.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const jsonData = await response.json();
            if (jsonData != null) {
                setStatusDropDown(jsonData);
            } else {
                console.warn("Received null data");
            }
        } catch (error) {
            console.error("Failed to fetch trigger List:", error.message);
        }
    }

    const getTriggerErrorsHandle = async () => {
        if (authUser == null) {
            return;
        }

        try {
            const response = await fetch(`${variables.API_URL}OfficeTrigger/GetTriggerErrors`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authUser.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const jsonData = await response.json();
            if (jsonData != null) {
                setErrorDropDown(jsonData);
            } else {
                console.warn("Received null data");
            }
        } catch (error) {
            console.error("Failed to fetch trigger List:", error.message);
        }
    }

    useEffect(() => {
        filterTriggerEmailsLog();
        getTriggerList();
        getTriggerStatusesHandle();
        getTriggerErrorsHandle();
    }, [authUser])

    useEffect(() => {
        filterTriggerEmailsLog();
        pagCount();
    }, [pageCount, pageSize, paginationLength])

    useEffect(() => {
        if (triggerId == "" && estateId == "" && contactId == "" && parserId == "" && status == "" && error == "" && appointCreateEndDate == "" && appointCreateStartDate == "" && appointmentEndDate == "" && appointmentStartDate == "") {
            filterTriggerEmailsLog();
        }
    }, [triggerId, estateId, contactId, parserId, status, error, appointCreateEndDate, appointCreateStartDate, appointmentEndDate, appointmentStartDate]);

    return (

        <div>
            <div className="row py-3">
                <div className="col-sm-12">
                    <h4>Trigger Transaction Log</h4>
                </div>
            </div>
            <div className="row pb-3">
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Trigger Id</label>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        className="combo-box"
                        options={triggerList}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                            <div ref={params.InputProps.ref}>
                                <input type="text" placeholder="Select TriggerId" {...params.inputProps} />
                            </div>
                            //<TextField {...params} label="Trigger list" />
                        )}
                        value={triggerId}
                        onChange={(event, newValue) => setTriggerId(newValue)}
                    />
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Parser Id</label>
                    <input type="number" className="form-control" id="parserIdInput" placeholder="Parser Id" value={parserId} onChange={e => setParserId(e.target.value)} />
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Estate Id</label>
                    <input type="number" className="form-control" id="clientIdInput" placeholder="Estate Id" value={estateId} onChange={e => setEstateId(e.target.value)} />
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Contact Id</label>
                    <input type="number" className="form-control" id="userIdInput" placeholder="Contact Id" value={contactId} onChange={e => setContactId(e.target.value)} />
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Error</label>
                    <select className="form-select" aria-label="Default select example" value={error} onChange={(e) => setError(e.target.value)}>
                        <option value="">Select Error</option>
                        {
                            statusDropDown.length > 0 ?
                                statusDropDown.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                                : <></>
                        }
                    </select>
                </div>
                <div className="col-sm-12 col-md-2 mb-3">
                    <label>Status</label>
                    <select className="form-select" aria-label="Default select example" value={status} onChange={(e) => setStatus(e.target.value)}>
                        <option value="">Select Status</option>
                        {
                            errorDropDown.length > 0 ?
                                errorDropDown.map((item) => {
                                    return (
                                        <option value={item}>{item}</option>
                                    )
                                })
                                : <></>
                        }
                    </select>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label className="mb-2">Appointment Created Date:</label>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label className="me-2"> Start Date </label>
                            <input type="date" className="form-select" id="" value={appointCreateStartDate} onChange={(e) => setAppointCreateStartDate(e.target.value)} />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label className="me-2">End Date </label>
                            <input type="date" className="form-select" id="" value={appointCreateEndDate} onChange={(e) => setAppointCreateEndDate(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12 col-md-6">
                    <label className="mb-2">Appointment Start Date:</label>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label className="me-2"> Start Date </label>
                            <input type="date" className="form-select" id="" value={appointmentStartDate} onChange={(e) => setAppointmentStartDate(e.target.value)} />
                        </div>
                        <div className="col-sm-12 col-md-6 mb-3">
                            <label className="me-2">End Date </label>
                            <input type="date" className="form-select" id="" value={appointmentEndDate} onChange={(e) => setAppointmentEndDate(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="col-12 mb-3">
                    <button className="btn bg-primary text-white px-4 me-2" onClick={filterTriggerEmailsLog}>Filter</button>
                    <button className="btn bg-primary text-white px-4 " onClick={clearFilterRecord}>Clear</button>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-12 mb-3 pe-0" style={{ overflowX: "auto", maxHeight: "500px" }}>
                    <table className="w-100 text-center">
                        <thead className="bg-primary text-white position-sticky top-0">
                            <tr>
                                <th className="px-2 border-start">Log Id</th>
                                <th className="px-2 border-start">Trigger Type</th>
                                <th className="px-2 border-start">Trigger Id</th>
                                <th className="px-2 border-start">Parser Id</th>
                                <th className="px-2 border-start">Appointment Id</th>
                                <th className="px-2 border-start">Log Creation Date</th>
                                <th className="px-2 border-start">Error</th>
                                <th className="px-2 border-start">Status</th>
                                <th className="px-2 border-start">Contact Id</th>
                                <th className="px-2 border-start">Estate Id</th>
                                {/*<th className="p-2">Email Open</th>*/}
                                {/*<th className="p-2">Click</th>*/}
                                <th className="px-2 border-start">Client Id</th>
                                <th className="px-2 border-start">Client Name</th>
                                <th className="px-2 border-start">Office Id </th>
                                <th className="px-2 border-start">Office Name</th>
                                <th className="px-2 border-start">User Id</th>
                                <th className="px-2 border-start">Start Date Of Appointment</th>
                                <th className="px-2 border-start">End Date Of Appointment</th>  
                            </tr>
                        </thead>
                        <tbody>

                            {

                                triggers.length > 0 ?
                                    triggers.map((item) => {

                                        const startDate = moment(item.appointmentStartDate).format('lll');
                                        const endDate = moment(item.appointmentEndDate).format('lll');
                                        const transactionData = moment(item.transactionDate).format('lll');

                                        return (
                                            <tr className="text-center" key={item.emailTransactionLogId}>
                                                <td className="border-start border-end border-bottom p-2 justify-content-center">{item.emailTransactionLogId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{(item.triggerType == 1) ? "Email" : "SMS"}</td>
                                                <td className="border-start border-end border-bottom p-2 justify-content-center">{item.triggerId}</td>
                                                <td className="border-start border-end border-bottom p-2 justify-content-center">{item.parserId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.calendarEventId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center grid-date-col-size">{transactionData != "Invalid date" ? transactionData : ""}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.error}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center grid-status-col-size">{item.status}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.contactId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.estateId}</td>
                                                {/*<td className="border-end border-bottom p-2 justify-content-center">{item.emailOpened}</td>*/}
                                                {/*<td className="border-end border-bottom p-2 justify-content-center">{item.linkClicked}</td>*/}
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.clientId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.clientName}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.officeId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.officeName}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center">{item.userId}</td>
                                                <td className="border-end border-bottom p-2 justify-content-center grid-date-col-size">{startDate != "Invalid date" ? startDate : "" }</td>
                                                <td className="border-end border-bottom p-2 justify-content-center grid-date-col-size">{endDate != "Invalid date" ? endDate : ""}</td>
                                            </tr>
                                        )
                                    }) :

                                    loading ?
                                        <tr>
                                            <td className="border py-3" colSpan="17">
                                                <div className="spinner-border text-primary text-center" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </td>
                                        </tr>

                                        :
                                        <tr >
                                            <td className="border py-3" colSpan="17">No Record Found</td>
                                        </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="col-4 d-flex align-items-center">
                    <label className="me-3">Page Size:</label>
                    <select className="form-select w-25" onChange={(e) => handlePageSize(e)} aria-label="Default select example">
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                        <option value="1000">1000</option>
                    </select>
                </div>
                <div className="col-8 text-end" style={{ display: "ruby" }}>
                    <Pagination count={finalPagingCount} page={pageCount} onChange={paginationNo} color="primary" />
                </div>
            </div>
        </div>

    )
}
