import React, { useState, useEffect, useContext, createContext } from 'react';
import { useParams, useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';

const AuthContext = createContext();

export function UseAuthContext() {

    return useContext(AuthContext);
}

export function AuthProvider(props) {
    const [authUser, setAuthUser] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const value = {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    }

    useEffect(() => {
        if (location.pathname.indexOf("pdfview") == -1) {
            const loggedInUser = window.localStorage.getItem('authorized_user');
            if (loggedInUser != 'null' && loggedInUser != null) {
                const foundUser = JSON.parse(loggedInUser);
                var currentDate = moment(new Date()).format('MMMM Do YYYY, h:mm:ss a');
                var tokenExpiryDate = moment(foundUser.expiryDateTime).format('MMMM Do YYYY, h:mm:ss a');
                if (currentDate < tokenExpiryDate) {
                    setAuthUser(foundUser);
                    setIsLoggedIn(true);
                }
                else {
                    window.localStorage.removeItem('authorized_user');
                    navigate("/login");
                }
            }
            else {
                navigate("/login");
            }
        }
        else {
            navigate("/pdfview");
        }
    }, []);

    useEffect(() => {
        if (authUser == null) {
            return;
        }
        if (authUser != null && location.pathname.indexOf("login") > 0) {
            localStorage.setItem("token", JSON.stringify(authUser)); // so you get it later
            navigate("/");
        }

        // Session Expire abdul saboor
        const expireSesseionTime = authUser.expiryDateTime;
        const expireSesseionTimeObj = new Date(expireSesseionTime)
        const timeDifference = expireSesseionTimeObj.getTime() - Date.now(); // get miliSceconds expireTime and currentTime

        // setTimeout function call on sessionExpiretime
        const logoutTimeout = setTimeout(() => {
            window.localStorage.removeItem("authorized_user");
            window.localStorage.removeItem("token");
            window.location.href = "/login";
        }, timeDifference);

        return () => {
            clearTimeout(logoutTimeout); // unmounted and clear all setTimeout value
        };

    }, [authUser]);

    return (
        <AuthContext.Provider value={value}>
            {
                props.children
            }
        </AuthContext.Provider>
    )
}