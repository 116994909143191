import React, { useEffect, useState } from 'react';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { useSearchParams } from 'react-router-dom';

const PdfLayoutView = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fileUrl, setFileUrl] = useState("");
    const [searchParams] = useSearchParams();

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        onPageChange: (event) => {
            setCurrentPage(event.currentPage + 1);
        },
    });

    const handleDocumentLoad = (event) => {
        setTotalPages(event.numPages);  
    };

    useEffect(() => {
        const file = searchParams.get("fileurl");
        if (file) {
            setFileUrl(file);
        }
    }, [])

    return (
        <> 
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        {
                            fileUrl != "" && fileUrl != null ? 
                                <div className="pdf-viewer-container">
                                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                        <Viewer
                                            fileUrl={fileUrl}
                                            plugins={[defaultLayoutPluginInstance]}
                                            onDocumentLoad={handleDocumentLoad}
                                            defaultScale={1}
                                        />
                                    </Worker>
                                </div>
                                :
                                <h1 className="text-center mt-5">Pdf File Not Found !</h1>
                        }
                       
                    </div>
                </div>
            </div>  
        </>
    );
};

export default PdfLayoutView;
